import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Container from '../atoms/Container';

const Nav = styled.nav`
  margin-bottom: 4rem;
  ul {
    padding-left: 0;
    list-style: none;
    margin: 0;
  }
`;

const ListItem = styled.li`
  display: inline-block;
  margin: 0;
  .button {
    margin-left: -1px;
  }
  &:first-child .button {
    margin-left: 0;
  }
`;

const CategoryNavigation = () => {
  const data = useStaticQuery(
    graphql`
        query {
            allContentfulCategory(
                sort: {fields: order}
            ) {
                nodes {
                    title
                    slug
                    id
                    order
                }
            }
        }
    `
  );

  return (
    <Container>
      <Nav className="Category-Navigation">
        <ul>
          <ListItem><Link className="button" to="/portfolio">all</Link></ListItem>

          {data.allContentfulCategory.nodes.map( category => (
            <ListItem key={category.id}>
              <Link className="button" to={`/portfolio/${category.slug}`}>{category.slug}</Link>
            </ListItem>
          ) )}
        </ul>
      </Nav>
    </Container>
  )
}

export default CategoryNavigation;
