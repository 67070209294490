import React, { useState, useEffect } from 'react';
import SEO from '../components/molecules/SEO';
import ProjectView from '../components/organisms/ProjectView';
import { graphql } from 'gatsby';
import CategoryNavigation from '../components/organisms/CategoryNavigation';

function Category( { data } ) {

  const [ posts, setPosts ] = useState( null );
  useEffect( () => {
    if ( data.categories.nodes.length > 0 ) {
      setPosts( data.categories.nodes );
    } else {
      setPosts( data.allProjects.nodes )
    }
  }, [ data.allProjects.nodes, data.categories.nodes ] );
  return (
    <>
      <SEO title="Portfolio"/>
      <div className="static-holder">
        <CategoryNavigation/>
      </div>
      <div className="content-holder">
        {posts && posts.map( project => (
          <ProjectView key={project.id} show={false} project={project}/>
        ) )}
      </div>
    </>
  )
}

export default Category;

export const pageQuery = graphql`
    query($category: String!, $skip: Int!, $limit: Int!) {
        categories: allContentfulProject(
            sort: {order: [ASC, DESC], fields: [order, createdAt]}
            limit: $limit
            skip: $skip
            filter: {category: {elemMatch: {slug: {eq: $category}}}}
        ) {
            nodes {
                title
                slug
                id
                client
                description {
                    description
                }
                images {
                    fluid(maxWidth: 1000) {
                        aspectRatio
                        ...GatsbyContentfulFluid_withWebp
                    }
                    file {
                        contentType
                        url
                        fileName
                    }
                }
                videoEmbeds {
                    embedCode {
                        embedCode
                    }
                    aspectRatio
                }
                category {
                    slug
                }
            }
        }
        allProjects: allContentfulProject(
            sort: {order: [ASC, DESC], fields: [order, createdAt]}
            limit: $limit
            skip: $skip
        ) {
            nodes {
                title
                slug
                id
                client
                description {
                    description
                }
                images {
                    fluid(maxWidth: 1000) {
                        aspectRatio
                        ...GatsbyContentfulFluid_withWebp
                    }
                    file {
                        contentType
                        url
                        fileName
                    }
                }
                videoEmbeds {
                    embedCode {
                        embedCode
                    }
                    aspectRatio
                }
                category {
                    slug
                }
            }
        }
    }
`;

