import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';
import MarkdownConverter from './MarkdownConverter';

const ImageHolder = styled.div`
  margin-right: 2rem;
  height: 40vh;
  flex-shrink: 0;
  &:last-child {
    margin-right: 0;
  }
  video {
  	height: 100%;
  	width: auto;
  	margin: 0;
  	display: block;
  }
`;

const EmbedHolder = styled.div`
  position: relative; 
  width: ${props => props.aspectRatio ? 40 * props.aspectRatio : 71}vh;
  height: 40vh;
  overflow: hidden; 
  
  iframe, 
  object, 
  embed { 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
  }
`;

function CarouselItem( { id, item, setActiveSlide, showHandler } ) {

  return (
  	<ImageHolder
  		onClick={() => {
  			showHandler(); 
  			setActiveSlide( id );
  			}}>

			{item.embedCode && <EmbedHolder aspectRatio={item.aspectRatio}><MarkdownConverter content={item.embedCode.embedCode}/></EmbedHolder>}

			{item.file && item.file.contentType.includes( 'video' ) && <video width="auto" height="40vh" controls playsInline preload="auto">
				<source src={`${item.file.url}#t=0.1`} type={item.file.contentType}/>
				Your browser does not support the video tag.
			</video>}

			{item.fluid && <GatsbyImage
				style={{
					height: '100%',
					width: `${item.fluid.aspectRatio * 40}vh`,
				}}
				loading="eager"
				fluid={item.fluid} />}

  	</ImageHolder>
	)
}

CarouselItem.propTypes = {
	item: PropTypes.object.isRequired,
	handleClick: PropTypes.func,
};

export default CarouselItem
