import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ProjectDetails from '../molecules/ProjectDetails';
import ProjectContent from '../molecules/ProjectContent';

const Holder = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 4rem;
`;

function ProjectView({project, show}) {

    const [showState, setShowState] = useState(!!show);

    function handleShowState() {
      setShowState(!showState);
    }

    return (
      <Holder>
        <ProjectContent show={showState} project={project}/>
        <ProjectDetails handleShowState={handleShowState} project={project}/>
      </Holder>
    )
}

ProjectView.propTypes = {
  show: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired
};

export default ProjectView;
