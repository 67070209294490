import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CarouselItem from '../atoms/CarouselItem';
import SlideShow from './SlideShow';
import SlideShowItem from '../molecules/SlideShowItem';

const Holder = styled.div`
  max-width: 100%;
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  margin-bottom: 2rem;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const CarouselWrapper = styled.div`
  display: flex;
`;

function Carousel( { projectClient, images, embeds } ) {

  const [ showSlideshow, setShowSlideshow ] = useState( false );
  const [ activeSlide, setActiveSlide ] = useState( 0 );
  const [ items, setItems ] = useState( null );


  useEffect(() => {
    if(!items) {
      if(embeds) {
        console.log('We have embeds');
        setItems( embeds.concat(images) );
      } else {
        setItems( images );
      }
    }
  }, [items, embeds, images]);

  if( !items ) return null;

  return (
    <>
      <Holder>
        <CarouselWrapper>
          {items.map( ( item, i ) => (
            <CarouselItem
              key={i}
              id={i}
              item={item}
              setActiveSlide={( i ) => setActiveSlide( i )}
              showHandler={() => setShowSlideshow( true )}/>
          ) )}
        </CarouselWrapper>
      </Holder>
      <SlideShow
        client={projectClient}
        show={showSlideshow}
        active={activeSlide}
        setActiveSlide={( i ) => setActiveSlide( i )}
        closeHandler={() => setShowSlideshow( false )}>
        {items.map( ( item, i ) => (
          <SlideShowItem key={i} item={item}/>
        ) )}
      </SlideShow>
    </>
  )
}

Carousel.propTypes = {
  projectClient: PropTypes.string,
  images: PropTypes.array.isRequired,
  embeds: PropTypes.array,
};

export default Carousel;
