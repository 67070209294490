import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Carousel from '../organisms/Carousel';
import Container from '../atoms/Container';
import MarkdownConverter from '../atoms/MarkdownConverter';

const Holder = styled.div`
  margin-top: ${props => props.show ? '2rem' : 0};
  max-height: ${props => props.show ? '100vh' : 0};
  transition: max-height 0.25s, margin-top 0.25s;
  overflow: hidden;
`;

const Line = styled.div`
  width: 4rem;
  height: 1px;
  background-color: ${props => props.theme.colours.white};
  margin-top: 2rem;
`;

const DescriptionHolder = styled.div`
  max-width: 64rem;
`;

function ProjectContent({ project, show }) {
  return (
    <Holder show={show}>
      <Carousel projectClient={project.client} images={project.images} embeds={project.videoEmbeds}/>
      <Container>
        <DescriptionHolder><MarkdownConverter content={project.description.description}/></DescriptionHolder>
        <Line/>
      </Container>

    </Holder>
  )
}

export default ProjectContent;

ProjectContent.propTypes = {
  project: PropTypes.object.isRequired,
  show: PropTypes.bool,
};
