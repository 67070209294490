import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '../atoms/Container';

const Button = styled.button`
  h2, p { 
    margin: 0; 
  }
  h2 {
    ${props => props.theme.typeStyles( -1 )};
  }
  b {
    font-style: normal;
  }
`;

function ProjectDetails({ project, handleShowState }) {
  return (
    <Container>
      <Button className="clearStyles" onClick={handleShowState}>
        <h2 className="p">{project.title}</h2>
        <p><b>{project.client}</b></p>
      </Button>
    </Container>
  )
}

export default ProjectDetails;

ProjectDetails.propTypes = {
  project: PropTypes.object,
  handleShowState: PropTypes.func,
};
